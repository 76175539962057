<template>
    <div class="content">
        <el-image
            v-loading="loading"
            element-loading-text="正在加载中"
            element-loading-spinner="el-icon-loading"
            class="img"
            fit="cover"
            :src="data.descImgUrl"
        />
    </div>
</template>

<script>
export default {
    name: "abroad",
    data() {
        return {
            data: {
                descImgUrl: ''
            },
            loading: true
        }
    },
    mounted() {
        let _this = this
        this.loading = true
        this.$api.get('/find/index/getShowPageData', {
            params: {
                pageType: 0
            }
        }).then(res => {
            // 判断图片是否加载完毕
            let image = new Image()
            image.src = res.data.descImgUrl
            image.onload = () => {
                _this.loading = false
            }
            // 赋值渲染
            this.data = res.data
        }).catch(err => {
            console.log(err)
            _this.loading = false
        })
    }
}
</script>

<style scoped lang="scss">
.content {
    background-color: #FFFFFF;
    padding: 10px;
    border-radius: 4px;
}

.img {
    width: 100%;
    height: 100%;
    min-height: 300px;
}
</style>